<script>
import settingsService from '@/services/Settings/settingsService';

import {vueTopprogress} from 'vue-top-progress';

import { DoubleBounce } from 'vue-loading-spinner';

import {
  CWLTipoTaxaPagamento,
  CWLTipoRepasse
} from '@/store/cwl_enums';

export default {
  data() {
    return {
      privateSettings: {
        integracao: {
            safe2pay: {
                safe2pay_api_keys: {},
                credit_card: {},
                doc: {},
                pix: {},
                ted: {},
                split: {}
            }
        }
      },
      publicSettings: {},
      list: [],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      settingsLoaded: false,
      isLoading: true,

      CWLTipoTaxaPagamento,
      CWLTipoRepasse
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
    DoubleBounce
  },
  methods: {
    async get() {
        this.publicSettings = await settingsService.getPublicSettings();
        this.privateSettings = await settingsService.getPrivateSettings();

        this.settingsLoaded = true;
        this.isLoading = false;
    },

    async save() {
        this.isLoading = true;

      try {
        
        await settingsService.updatePublicSettings(this.publicSettings);
        await settingsService.updatePrivateSettings(this.privateSettings);

        this.$router.push('/settings#integracao');

        this.isLoading = false;
        this.privateSettings = {};
        this.publicSettings = {};

        this.$notify({
            title: 'Integração realizada com sucesso!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.$notify({
          title: 'Erro ao salvar integração, tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    checkSandboxAtivo() {
      this.$refs.marketplace_token_sandbox_active.click();
    },

    integracaoSafe2PayCheck(element) {
      let elementType = element.srcElement.getAttribute('data-type');
      if ( elementType == "habilitar" ) {
        let ref = element.srcElement.getAttribute('for');
        this.integracaoSafe2PayCheckHabilitar(this.$refs[ref]);
      }
    },
    integracaoSafe2PayCheckHabilitar(element) {
      element.click();
    },

    checkHabilitarIntegracao() {
      this.$refs.integracao_safe2pay_habilitar.click();
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="save()">
        <h5 slot="header" class="title">
          <router-link to="/settings#integracao" class="fa fa-arrow-left"></router-link>
          Voltar
        </h5>

        <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
        </div>

        <div class="row" v-if="settingsLoaded && !isLoading">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <input
                            name="integracao_safe2pay_habilitar"
                            ref="integracao_safe2pay_habilitar"
                            type="checkbox"
                            v-model="privateSettings.integracao.safe2pay.enabled"
                        />
                        <label
                            for="integracao_safe2pay_habilitar"
                            style="margin-left: 5px;"
                            @click="checkHabilitarIntegracao"
                        >
                            HABILITAR INTEGRAÇÃO
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                  <div class="col-md-5 mt-3">
                    <base-input
                      label="Token Marketplace Produtivo"
                      placeholder="Informe o token produtivo do Marketplace"
                      v-model="privateSettings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_prd"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-5 mt-3">
                    <base-input
                      label="Token Marketplace Sandox"
                      placeholder="Informe o token sandbox do Marketplace"
                      v-model="privateSettings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-2 mt-3">
                    <input
                      name="marketplace_token_sandbox_active"
                      ref="marketplace_token_sandbox_active"
                      type="checkbox"
                      v-model="privateSettings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox_active"
                    />
                    <label
                      for="marketplace_token_sandbox_active"
                      style="margin-left: 5px; margin-top: 36px"
                      @click="checkSandboxAtivo"
                    >
                      Sandbox Ativo</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Tipo de Repasse"
                    >
                      <select
                        class="form-control"
                        v-model="privateSettings.integracao.safe2pay.split.tipo_repasse"
                        required
                      >
                        <option :value="CWLTipoRepasse.Fixo">Fixo</option>
                        <option :value="CWLTipoRepasse.Percentual">Percentual</option>
                      </select>
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <label>Valor do Repasse Plataforma</label>
                    <base-input
                      v-model="privateSettings.integracao.safe2pay.split.valor_plataforma"
                      placeholder="Valor do Repasse"
                      required
                      v-mask="['##.##']"
                    ></base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <label>Valor do Repasse Beneficiário</label>
                    <base-input
                      v-model="privateSettings.integracao.safe2pay.split.valor_beneficiario"
                      placeholder="Valor do Repasse"
                      required
                      v-mask="['##.##']"
                    ></base-input>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-12 mt-12">
                    <table class="table table-striped table-bordered">
                      <tbody>
                        <!-- CARTÃO DE CRÉDITO -->
                        <tr class="custom-cursor-pointer">
                          <td style="width: 80%;">
                            <b>Cartão de Crédito</b>
                          </td>
                          <td style="text-align: center; cursor: pointer;">
                            <div class="row">
                              <div class="custom-table-checkbox-enabled">
                                <input
                                  name="integracao_safe2pay_cartao_credito"
                                  ref="integracao_safe2pay_cartao_credito"
                                  type="checkbox"
                                  v-model="privateSettings.integracao.safe2pay.credit_card.is_enabled"
                                  class="custom-cursor-pointer"
                                />
                              </div>
                              <div class="custom-table-label-enabled">
                                <label
                                  for="integracao_safe2pay_cartao_credito"
                                  data-type="habilitar"
                                  @click="integracaoSafe2PayCheck"
                                >
                                  Habilitar
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="custom-cursor-pointer" v-if="privateSettings.integracao.safe2pay.credit_card.is_enabled">
                          <td colspan="2" class="forma-pagamento-configuracoes">
                            <div class="row">
                              <div class="col-md-3 mt-3 remove-margin-top-col-3">
                                <base-input label="Repassar Taxa p/ Subconta">
                                  <select
                                    class="form-control"
                                    v-model="privateSettings.integracao.safe2pay.credit_card.isSubaccountTaxPayer"
                                    required
                                  >
                                    <option value="1">SIM</option>
                                    <option value="0">NÃO</option>
                                  </select>
                                </base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <base-input
                                  label="Tipo de Taxa"
                                >
                                  <select
                                    class="form-control"
                                    v-model="privateSettings.integracao.safe2pay.credit_card.taxTypeName"
                                    required
                                  >
                                    <option :value="CWLTipoTaxaPagamento.Fixo">Fixo</option>
                                    <option :value="CWLTipoTaxaPagamento.Percentual">Percentual</option>
                                  </select>
                                </base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor da Taxa</label>
                                <base-input
                                  v-model="privateSettings.integracao.safe2pay.credit_card.tax"
                                  placeholder="Valor da Taxa"
                                  required
                                  v-mask="['##.##']"
                                ></base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Mínimo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.credit_card.min_value"
                                  placeholder="Valor Mínimo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Máximo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.credit_card.max_value"
                                  placeholder="Valor Máximo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>

                        <!-- BOLETO -->
                        <tr class="custom-cursor-pointer">
                          <td>
                            <b>Boleto</b>
                          </td>
                          <td style="text-align: center; cursor: pointer;">
                            <div class="row">
                              <div class="custom-table-checkbox-enabled">
                                <input
                                  name="integracao_safe2pay_boleto"
                                  ref="integracao_safe2pay_boleto"
                                  type="checkbox"
                                  v-model="privateSettings.integracao.safe2pay.doc.is_enabled"
                                  class="custom-cursor-pointer"
                                />
                              </div>
                              <div class="custom-table-label-enabled">
                                <label
                                  for="integracao_safe2pay_boleto"
                                  data-type="habilitar"
                                  @click="integracaoSafe2PayCheck">
                                  Habilitar
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="custom-cursor-pointer" v-if="privateSettings.integracao.safe2pay.doc.is_enabled">
                          <td colspan="2" class="forma-pagamento-configuracoes">
                            <div class="row">
                              <div class="col-md-3 mt-3 remove-margin-top-col-3">
                                <base-input label="Repassar Taxa p/ Subconta">
                                  <select
                                    class="form-control"
                                    v-model="privateSettings.integracao.safe2pay.doc.isSubaccountTaxPayer"
                                    required
                                  >
                                    <option value="1">SIM</option>
                                    <option value="0">NÃO</option>
                                  </select>
                                </base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <base-input
                                  label="Tipo de Taxa"
                                >
                                  <select
                                    class="form-control"
                                    v-model="privateSettings.integracao.safe2pay.doc.taxTypeName"
                                    required
                                  >
                                    <option :value="CWLTipoTaxaPagamento.Fixo">Fixo</option>
                                    <option :value="CWLTipoTaxaPagamento.Percentual">Percentual</option>
                                  </select>
                                </base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor da Taxa</label>
                                <base-input
                                  v-model="privateSettings.integracao.safe2pay.doc.tax"
                                  placeholder="Valor da Taxa"
                                  required
                                  v-mask="['##.##']"
                                ></base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Mínimo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.doc.min_value"
                                  placeholder="Valor Mínimo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Máximo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.doc.max_value"
                                  placeholder="Valor Máximo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-3 mt-3">
                                <label>Multa após o Vencimento</label>
                                <base-input
                                  v-model="privateSettings.integracao.safe2pay.doc.multa_vencimento"
                                  placeholder="Multa após o Vencimento"
                                  required
                                  v-mask="['#.##']"
                                ></base-input>
                              </div>
                              <div class="col-md-3 mt-3">
                                <label>Juros após o Vencimento</label>
                                <base-input
                                  v-model="privateSettings.integracao.safe2pay.doc.juros_vencimento"
                                  placeholder="Juros após o Vencimento"
                                  required
                                  v-mask="['#.##']"
                                ></base-input>
                              </div>
                              <div class="col-md-3 mt-3">
                                <label>Vencimento (em dias)</label>
                                <base-input
                                  v-model="privateSettings.integracao.safe2pay.doc.vencimento_em_dias"
                                  placeholder="Vencimento em dias pós aporte"
                                  required
                                  v-mask="['##']"
                                ></base-input>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <!-- PIX -->
                        <tr class="custom-cursor-pointer">
                          <td>
                            <b>PIX</b>
                          </td>
                          <td style="text-align: center; cursor: pointer;">
                            <div class="row">
                              <div class="custom-table-checkbox-enabled">
                                <input
                                  name="integracao_safe2pay_pix"
                                  ref="integracao_safe2pay_pix"
                                  type="checkbox"
                                  v-model="privateSettings.integracao.safe2pay.pix.is_enabled"
                                  class="custom-cursor-pointer"
                                />
                              </div>
                              <div class="custom-table-label-enabled">
                                <label
                                  for="integracao_safe2pay_pix"
                                  data-type="habilitar"
                                  @click="integracaoSafe2PayCheck"
                                >
                                  Habilitar
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="custom-cursor-pointer" v-if="privateSettings.integracao.safe2pay.pix.is_enabled">
                          <td colspan="2" class="forma-pagamento-configuracoes">
                            <div class="row">
                              <div class="col-md-3 mt-3 remove-margin-top-col-3">
                                <base-input label="Repassar Taxa p/ Subconta">
                                  <select
                                    class="form-control"
                                    v-model="privateSettings.integracao.safe2pay.pix.isSubaccountTaxPayer"
                                    required
                                  >
                                    <option value="1">SIM</option>
                                    <option value="0">NÃO</option>
                                  </select>
                                </base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <base-input
                                  label="Tipo de Taxa"
                                >
                                  <select
                                    class="form-control"
                                    v-model="privateSettings.integracao.safe2pay.pix.taxTypeName"
                                    required
                                  >
                                    <option :value="CWLTipoTaxaPagamento.Fixo">Fixo</option>
                                    <option :value="CWLTipoTaxaPagamento.Percentual">Percentual</option>
                                  </select>
                                </base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor da Taxa</label>
                                <base-input
                                  v-model="privateSettings.integracao.safe2pay.pix.tax"
                                  placeholder="Valor da Taxa"
                                  required
                                  v-mask="['##.##']"
                                ></base-input>
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Mínimo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.pix.min_value"
                                  placeholder="Valor Mínimo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Máximo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.pix.max_value"
                                  placeholder="Valor Máximo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>

                        <!-- TED -->
                        <tr class="custom-cursor-pointer">
                          <td>
                            <b>TED</b>
                          </td>
                          <td style="text-align: center; cursor: pointer;">
                            <div class="row">
                              <div class="custom-table-checkbox-enabled">
                                <input
                                  name="integracao_safe2pay_ted"
                                  ref="integracao_safe2pay_ted"
                                  type="checkbox"
                                  v-model="privateSettings.integracao.safe2pay.ted.is_enabled"
                                  class="custom-cursor-pointer"
                                />
                              </div>
                              <div class="custom-table-label-enabled">
                                <label
                                  for="integracao_safe2pay_ted"
                                  data-type="habilitar"
                                  @click="integracaoSafe2PayCheck"
                                >
                                  Habilitar
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="custom-cursor-pointer" v-if="privateSettings.integracao.safe2pay.ted.is_enabled">
                          <td colspan="2" class="forma-pagamento-configuracoes">
                            <div class="row">
                              <div class="col-md-2 mt-2">
                                <label>Valor Mínimo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.ted.min_value"
                                  placeholder="Valor Mínimo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                              <div class="col-md-2 mt-2">
                                <label>Valor Máximo</label>
                                <currency-input
                                  class="form-control"
                                  type="currency"
                                  v-model="privateSettings.integracao.safe2pay.ted.max_value"
                                  placeholder="Valor Máximo"
                                  required
                                  currency="BRL"
                                  locale="pt-br"
                                  :auto-decimal-mode="true"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <button type="submit" class="btn btn-sm">Salvar</button>
            </div>
        </div>
      </form>
    </card>
  </div>
</template>
